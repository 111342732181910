import {
  Spinner
} from "@fluentui/react-components";
import { Provider, teamsDarkV2Theme, teamsV2Theme } from "@fluentui/react-northstar";
import { app } from "@microsoft/teams-js";
import { useTeamsUserCredential, } from "@microsoft/teamsfx-react";
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { TeamsFxContext } from "components/Context";
import config from "config";
import "Libs/Localization";
import { i18next } from "Libs/Localization";
import { Props, UserModel } from "Models";
import moment from "moment";
import 'moment/locale/en-gb';
import 'moment/locale/nl';
import Dashboard from "pages/Dashboard";
import Privacy from "pages/Privacy";
import TermsOfUse from "pages/TermsOfUse";
import { FC, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Navigate, Route, HashRouter as Router, Routes } from "react-router-dom";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 2000,
      retry: 0,
    },
  },
});


const App: FC<Props> = (props) => {

  const [auth, setAuth] = useState<{ login: boolean, user: UserModel | null, token: string }>({
    login: false,
    user: null,
    token: ""
  })
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  });
  useEffect(() => {
    app.getContext().then((context) => {
      const lang = context.app.locale === 'nl-nl' ? 'nl' : 'en';
      console.log(context.app.locale)
      i18next.changeLanguage(lang);
      moment.locale(context.app.locale);
    })
  }, [])


  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential, auth, setAuth }}>
      <Provider theme={themeString === "dark" ? teamsDarkV2Theme : teamsV2Theme}>
        <QueryClientProvider client={queryClient}>
          <Router>
            {loading ? (
              <Spinner style={{ margin: 100 }} />
            ) : (
              <Routes>
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/termsofuse" element={<TermsOfUse />} />
                <Route path="/app/:tab" element={<Dashboard {...props} />} />
                <Route path="*" element={<Navigate to={"/app/INBOX"} />}></Route>
              </Routes>
            )}
          </Router>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </Provider>
    </TeamsFxContext.Provider>
  );
}


export default withTranslation()(App);