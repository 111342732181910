import { AutomationModel, ResponseModel } from "Models";
import { Http } from "Request";


export default class AutomationService {
    public static getAutomation(params = {}): Promise<ResponseModel<AutomationModel>> {
        return new Promise((resolve, reject) => {
            Http.get('automations', params)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static update(params = {}): Promise<ResponseModel<AutomationModel>> {
        return new Promise((resolve, reject) => {
            Http.post('automations', params)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }
}