import { Loader } from "@fluentui/react-northstar";
import { useData } from "@microsoft/teamsfx-react";
import { InfiniteData, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { ConversationModel, PageModel, Props, ResponseModel } from "Models";
import { Http } from "Request";
import { AuthService, UserService } from "Services";
import { AccessDenied } from "components/AccessDenied";
import ContactList from "components/ContactList";
import { TeamsFxContext } from "components/Context";
import MessageBox from "components/MessageBox";
import { Startup } from "components/Startup";
import { concat, filter, findIndex, map, uniqBy } from "lodash";
import { FC, useContext, useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useParams } from "react-router-dom";
import { socket } from "socket";

const Dashboard: FC<Props> = (props) => {
    const params = useParams()
    const [state, setState] = useState<{
        q: string,
        selected: ConversationModel | null,
        filter: 'UNREAD' | 'UNANSWERED' | 'ALL',
        needConsent: boolean,
        loading: boolean
    }>({
        q: '',
        filter: 'ALL',
        selected: null,
        needConsent: false,
        loading: true
    })

    const { teamsUserCredential, themeString, auth, setAuth } = useContext(TeamsFxContext);
    const queryClient = useQueryClient();
    const conversationQuery = useInfiniteQuery({
        initialPageParam: 1,
        enabled: auth.login,
        queryKey: ['conversations', state.q, state.filter],
        queryFn: ({ pageParam }) => {
            const body = {
                q: state.q,
                type: params.tab,
                filter: state.filter,
                page: pageParam,
                limit: 50
            }
            return UserService.users(body)
        },
        getNextPageParam: (lastPage) => {
            return lastPage.data.lastPage > lastPage.data.currentPage ? lastPage.data.currentPage + 1 : undefined
        },
        select: (data): Array<ConversationModel> => {
            return concat(...map(data.pages, page => page.data.items || []));
        },
    })

    const { loading } = useData(async () => {
        if (!teamsUserCredential) {
            throw new Error("TeamsFx SDK is not initialized.");
        }
        if (state.needConsent) {
            await teamsUserCredential!.login(["User.Read"]);
            setState(prevState => {
                prevState.needConsent = true;
                return prevState;
            })
        }
        const token = await teamsUserCredential.getToken("")
        const user = await teamsUserCredential.getUserInfo()
        if (user && token) {
            const resp = await AuthService.authenticate({ token: token.token, tenantId: user.tenantId });
            if (resp.data) {
                Http.defaults.headers.common['Authorization'] = `Bearer ${resp.data?.token}`
                setAuth({ login: true, ...resp.data })
            }
            return resp;
        }
    })

    useEffect(() => {
        setState(prevState => {
            prevState.filter = sessionStorage.getItem('filter') as 'UNREAD' | 'UNANSWERED' || 'ALL'
            return { ...prevState }
        })

        const initSoket = () => {
            socket.on(`${auth?.user?.tenantId}/new:conversation`, (data: { header: { senderId: string }, body: ConversationModel }) => {
                if (data) {
                    const { body, header } = data
                    const index = findIndex(conversationQuery.data, u => u.uuid === body.uuid);
                    if (index === -1) {
                        const myContact = findIndex(body.users, u => u.uuid === auth.user?.uuid) > -1
                        if (params.tab === 'MY_CONVERSATIONS' && !myContact) {
                            return;
                        }
                        queryClient.setQueryData(['conversations', state.q, state.filter], (prevState: InfiniteData<ResponseModel<PageModel<ConversationModel>>>) => {
                            prevState.pages = [...map(prevState.pages, page => {
                                if (page.data.currentPage === 1) {
                                    page.data.items = [body, ...page.data.items]
                                }
                                page.data.items = uniqBy(page.data.items, 'uuid')
                                return page;
                            })]
                            return { ...prevState };
                        })

                        setState(prevState => {
                            if (myContact && !prevState.selected) {
                                prevState.selected = data.body
                            }
                            return { ...prevState }
                        })
                    }
                }
            });

            socket.on(`${auth?.user?.tenantId}/update:conversation`, (data: { header: { senderId: string, entityId: string, action: string }, body: ConversationModel }) => {
                if (data) {
                    const { body, header } = data
                    queryClient.setQueryData(['conversations', state.q, state.filter], (prevState: InfiniteData<ResponseModel<PageModel<ConversationModel>>>) => {
                        prevState.pages = [...map(prevState.pages, page => {
                            // if (page.data.currentPage === 1) {
                            //     page.data.items = [data.body, ...page.data.items]
                            // }
                            page.data.items = map(page.data.items, conversation => {
                                if (conversation.uuid === data.body.uuid) {
                                    return data.body
                                }
                                return conversation
                            })
                            if (params.tab === 'MY_CONVERSATIONS') {
                                page.data.items = filter(page.data.items, conversation => {
                                    return findIndex(conversation.users, u => u.uuid === auth.user?.uuid) > -1
                                })
                            }
                            page.data.items = uniqBy(page.data.items, 'uuid')
                            return page;
                        })]
                        return { ...prevState };
                    });

                    setState(prevState => {
                        if (prevState.selected?.uuid === data.body.uuid || header.action === 'NEW') {
                            prevState.selected = data.body
                        }
                        return { ...prevState }
                    })
                }
            })
            socket.connect()
        }
        initSoket()
        return () => {
            socket.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.tab, auth?.user?.tenantId]);

    useEffect(() => {
        if (isMobile) {
            return
        }
        if (!state.selected && conversationQuery.data) {
            setState(prevState => {
                prevState.selected = conversationQuery.data[0] || null
                return { ...prevState }
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversationQuery.data])

    const onSearch = (q: string) => {
        setState(prevState => {
            prevState.q = q;
            return { ...prevState }
        })
    }

    const onItemSelected = (conversation: ConversationModel) => {
        const hasAccess = findIndex(conversation.users, u => u.uuid === auth.user?.uuid) > -1;
        if (hasAccess) {
            conversation.count = 0;
        }
        setState(prevState => {
            prevState.selected = conversation
            return { ...prevState }
        })
        queryClient.setQueryData(['conversations', state.q, state.filter], (prevState: InfiniteData<ResponseModel<PageModel<ConversationModel>>>) => {
            prevState.pages = [...map(prevState.pages, page => {
                page.data.items = map(page.data.items, message => {
                    if (message.uuid === conversation.uuid) {
                        return conversation
                    }
                    return message
                })
                return page;
            })]
            return { ...prevState };
        })
    }

    if (!loading && !auth?.login) {
        return <AccessDenied {...props} />
    }

    const onBackClick = () => {
        setState(prevState => {
            prevState.selected = null;
            return { ...prevState }
        })
    }
    const handleFilter = (flter: 'UNREAD' | 'UNANSWERED' | 'ALL') => {
        sessionStorage.setItem('filter', flter)
        setState(prevState => {
            prevState.filter = flter;
            return { ...prevState }
        })
    }

    const onDeleteConversation = (item: ConversationModel) => {
        queryClient.setQueryData(['conversations', state.q, state.filter], (prevState: InfiniteData<ResponseModel<PageModel<ConversationModel>>>) => {
            prevState.pages = [...map(prevState.pages, page => {
                page.data.items = filter(page.data.items, item => item.uuid !== item.uuid)
                return page;
            })]
            return { ...prevState };
        })
    }


    const onEditConversation = (item: ConversationModel, edit: boolean = false) => {
        queryClient.setQueryData(['conversations', state.q, state.filter], (prevState: InfiniteData<ResponseModel<PageModel<ConversationModel>>>) => {
            prevState.pages = [...map(prevState.pages, page => {
                if (edit) {
                    page.data.items = map(page.data.items, item => {
                        if (item.uuid === item.uuid) {
                            return item
                        }
                        return item
                    })
                }
                return page;
            })]
            return { ...prevState };
        })
    }

    return (
        <>
            {!loading ? (<div className={isMobile ? `mobile-row ${themeString}` : `row ${themeString}`}>
                <div className={state.selected ? "side" : "side side-selected"}>
                    <ContactList
                        {...props}
                        onDeleteConversation={onDeleteConversation}
                        onEditConversation={onEditConversation}
                        filter={state.filter}
                        selected={state.selected}
                        users={conversationQuery.data || []}
                        onSearch={onSearch}
                        onFilterClick={handleFilter}
                        onItemSelected={onItemSelected}
                        loadMore={conversationQuery.fetchNextPage}
                        loading={conversationQuery.isFetchingNextPage}
                    />
                </div>
                <div className={state.selected ? "main main-selected" : "main"}>
                    {!conversationQuery.data?.length && !state.q ? (<Startup {...props} />) : state.selected ? (<MessageBox {...props} selected={state.selected} key={state.selected?.uuid} onBackClick={onBackClick} />) : null}
                </div>
            </div>)
                : (<div className="row">
                    <Loader />
                </div>)}
        </>
    );
}

export default Dashboard;
