import { ResponseModel } from "Models";
import { Http } from "Request";


export default class MessageService {
    public static changeLogs(params = {}): Promise<ResponseModel<any>> {
        return new Promise((resolve, reject) => {
            Http.get('tasks', { params })
                .then((res) => {
                    return resolve(res?.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static changeLogByKey(key: string, params = {}): Promise<ResponseModel<any>> {
        return new Promise((resolve, reject) => {
            Http.get(`tasks/${key}`, { params })
                .then((res) => {
                    return resolve(res?.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static getImage(id: string, params = {}): Promise<Blob> {
        return new Promise((resolve, reject) => {
            Http.get(`tasks/${id}/images`, { params })
                .then((res) => {
                    return resolve(res?.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

}