import { ResponseModel, UserModel } from "Models";
import { Http } from "Request";


export default class AuthService {
    public static authenticate(params = {}): Promise<ResponseModel<{ user: UserModel, token: string }>> {
        return new Promise((resolve, reject) => {
            Http.post('authenticate', params)
                .then((res) => {
                    return resolve(res.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }
}