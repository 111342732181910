
import {
    Alert,
    Button,
    Dialog,
    Dropdown,
    Flex,
    Form,
    Text
} from "@fluentui/react-northstar";
import { ConversationModel, Props, UserModel, } from "Models";
import { ConversationService, UserService } from "Services";
import { filter, map } from "lodash";
import { FC, useEffect, useState } from "react";

export const AssignUser: FC<Props & { open: boolean, onClose: () => void, conversation: ConversationModel }> = (props) => {
    const { open, onClose, conversation, t } = props
    const [users, setUsers] = useState<Array<UserModel>>([])
    const [state, setState] = useState<{
        loading: boolean,
        alert: {
            open: boolean
            type: 'success' | 'danger' | 'info' | 'warning' | 'default'
            message: string
        },
        fields: {
            users: Array<string>
        }
        errors: {
            user: string
        }
    }>({
        loading: false,
        alert: {
            open: false,
            type: 'success',
            message: ''
        },
        fields: {
            users: map(conversation.users, user => user.uuid)
        },
        errors: {
            user: ''
        }
    })

    useEffect(() => {
        const getUsers = () => {
            UserService.getManagers().then((res) => {
                setUsers(res.data)
            }).catch((err) => {
                console.log(err)
            })
        }
        getUsers()
    }, [])
    const handleUserChange = (e: any, data: any) => {
        setState(prevState => {
            prevState.fields.users = map(data?.value, item => item.uuid);
            prevState.errors.user = !prevState.fields.users?.length ? 'Required' : '';
            return { ...prevState }
        });
    }

    const assignUser = () => {
        let error = false
        if (!state.fields.users.length) {
            setState((prevState) => {
                prevState.errors.user = 'Required'
                return { ...prevState }
            })
            error = true
        }
        if (error) {
            return
        }

        setState((prevState) => {
            prevState.loading = true
            return { ...prevState }
        })
        ConversationService.update(conversation.uuid, state.fields).then((resp) => {
            if (resp.status === 200) {
                setState((prevState) => {
                    prevState.alert = {
                        open: true,
                        type: 'success',
                        message: 'Gebruiker toegewezen'
                    }
                    prevState.fields = {
                        users: []
                    }
                    prevState.errors = {
                        user: ''
                    }
                    return { ...prevState }
                });
                setTimeout(() => {
                    setState((prevState) => {
                        prevState.loading = false
                        prevState.alert = {
                            open: false,
                            type: 'success',
                            message: ''
                        }
                        return { ...prevState }
                    })
                    onClose()
                }, 2000)
            } else {
                setState((prevState) => {
                    prevState.alert = {
                        open: true,
                        type: 'danger',
                        message: 'Something went wrong'
                    }
                    return { ...prevState }
                });
                setTimeout(() => {
                    setState((prevState) => {
                        prevState.loading = false
                        prevState.alert = {
                            open: false,
                            type: 'success',
                            message: ''
                        }
                        return { ...prevState }
                    })
                }, 2000)
            }
        })
    }

    const options = map(users, (item, key: number) => ({
        key: key,
        uuid: item?.uuid,
        selected: state?.fields?.users.includes(item?.uuid),
        header: `${item?.firstName} ${item?.lastName}`,
    }))

    return (
        <Dialog
            open={open}
            header={
                <Flex column>
                    <Text content={t('DIALOG.ASSIGN_CONVERSATION.TITLE')} />
                    <Text weight='light' size="small" content={t('DIALOG.ASSIGN_CONVERSATION.DESCRIPTION')} />
                </Flex>
            }
            footer={
                <Flex gap="gap.small" hAlign="end">
                    <Button disabled={state.loading} onClick={onClose}>{t('BUTTON.CANCEL')}</Button>
                    <Button disabled={state.loading} primary onClick={assignUser}>{t('BUTTON.SAVE')}</Button>
                </Flex>
            }
            content={
                <Form>
                    <Flex gap="gap.small" column>
                        {state.alert.open && <Flex.Item>
                            <Alert
                                success={state.alert.type === 'success'}
                                danger={state.alert.type === 'danger'}
                                info={state.alert.type === 'info'}
                                content={state.alert.message}
                            />
                        </Flex.Item>
                        }
                        <Flex.Item size="size.full">
                            <Dropdown
                                search
                                multiple
                                onChange={handleUserChange}
                                items={options}
                                value={filter(options, (item) => state?.fields?.users.includes(item?.uuid))}
                                fluid
                                placeholder={t('INPUT.SELECT_USER.PLACEHOLDER')}
                                title={t('INPUT.SELECT_USER.TITLE')}
                                error={!!state?.errors?.user}
                            />
                        </Flex.Item>
                    </Flex>
                </Form>
            }
        />
    )
}