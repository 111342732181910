
import axios, { AxiosRequestConfig, AxiosStatic, CancelTokenSource as TokenSource, CancelTokenStatic as TokenStatic } from 'axios';


axios.defaults.baseURL = `${process.env.REACT_APP_API_ROOT_URL}/api/v1`;

axios.interceptors.request.use((config) => {
    return config;
});

axios.interceptors.response.use(
    (resp: any) => {
        return resp;
    },
    (error: any) => {
        const { status } = error?.response || {};
        if (status) {
            switch (status) {
                case 400:
                    break;
                case 401:
                    break;
                case 403:
                    break;
                case 404:
                    break;
                case 500:
                    break;
                default:
                    break;
            }
        }
        return error.response;
    }
);


export interface CancelTokenSource extends TokenSource { }
export interface CancelTokenStatic extends TokenStatic { }
export interface RequestConfig extends AxiosRequestConfig { }
export interface HttpModel extends AxiosStatic { }

export const Http: HttpModel = axios;
export const FixedHttp = axios.create();
