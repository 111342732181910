
import {
    Alert,
    Button,
    Card,
    Dialog,
    Dropdown,
    Flex,
    Form,
    Input,
    SendIcon,
    Text
} from "@fluentui/react-northstar";
import { ComponentModel, ConversationModel, Props, TemplateModel, VaribleModel } from "Models";
import { MessageService } from "Services";
import { capitalize, filter, findIndex, forEach, map, set } from "lodash";
import { FC, useContext, useEffect, useState } from "react";
import { TeamsFxContext } from "./Context";

export const SendTemplateMessage: FC<Props & { selected: ConversationModel | null }> = (props) => {
    const { selected, t } = props;
    const { auth } = useContext(TeamsFxContext);
    const [templates, setTemplates] = useState<Array<TemplateModel>>([])
    const [state, setState] = useState<{
        loading: boolean,
        alert: {
            open: boolean
            type: 'success' | 'danger' | 'info' | 'warning' | 'default'
            message: string
        },
        open: boolean
        template: TemplateModel | null
        errors: {
            phone: string
            firstName: string
            lastName: string
            template: string
        }
    }>({
        loading: false,
        open: false,
        template: null,
        alert: {
            open: false,
            type: 'success',
            message: ''
        },
        errors: {
            phone: '',
            firstName: '',
            lastName: '',
            template: ''
        }
    })
    const sendMessage = () => {
        let error = false

        if (!state.template) {
            setState((prevState) => {
                prevState.errors.template = 'Required'
                return { ...prevState }
            })
            error = true
        }
        if (error) {
            return
        }
        setState((prevState) => {
            prevState.loading = true
            return { ...prevState }
        })
        const payload = {
            clientId: selected?.client?.uuid || '',
            content: getMessageContent(),
            template: {
                name: state.template?.name,
                language: state.template?.language,
                components: state.template?.components
            }
        }

        MessageService.sendMessage(payload).then((resp) => {
            if (resp.status === 200) {
                setState((prevState) => {
                    prevState.alert = {
                        open: true,
                        type: 'success',
                        message: t('MESSAGE.TEMPLATE.SUCCESS')
                    }
                    prevState.template = null
                    prevState.errors = {
                        phone: '',
                        firstName: '',
                        lastName: '',
                        template: ''
                    }
                    return { ...prevState }
                });
                setTimeout(() => {
                    setState((prevState) => {
                        prevState.loading = false
                        prevState.open = false;
                        prevState.alert = {
                            open: false,
                            type: 'success',
                            message: ''
                        }
                        return { ...prevState }
                    })
                }, 2000)
            }
            if (resp.status === 400) {
                setState((prevState) => {
                    prevState.loading = false;
                    prevState.alert = {
                        open: true,
                        type: 'danger',
                        message: resp.message
                    }
                    return { ...prevState }
                });
                setTimeout(() => {
                    setState((prevState) => {
                        prevState.alert = {
                            open: false,
                            type: 'success',
                            message: ''
                        }
                        return { ...prevState }
                    })
                }, 2000)
            }
        })
    }
    const getVariables = (text: string): Array<VaribleModel> => {
        const arr: Array<VaribleModel> = [];
        for (let i = 0; i < 100; i++) {
            if (text.includes(`{{${i}}}`)) {
                arr.push({
                    key: `{{${i}}}`,
                    text: '',
                    type: 'text'
                })
            }
        }
        return arr;
    }

    useEffect(() => {
        const getTemplates = () => {
            MessageService.getTemplates().then((resp) => {
                if (resp) {
                    forEach(resp.data, (template) => {
                        template.components = map(filter(template.components, (component) => ['BODY', 'BUTTON', 'CAROUSEL', 'HEADER', 'LIMITED_TIME_OFFER', 'ORDER_STATUS'].includes(component.type)), (component) => {
                            component.parameters = getVariables(component.text)
                            return component
                        })
                    })
                    const tems = map(resp.data, item => ({
                        ...item,
                        key: item.id,
                        header: item?.name,
                    }));
                    setTemplates(tems)
                }
            });
        }
        getTemplates()
        return () => {
            setTemplates([])
        }
    }, [selected?.uuid])

    const handleVariableChange = (e: React.SyntheticEvent<HTMLElement>, { name, value }: any) => {
        setState(prevState => {
            if (prevState.template) {
                set(prevState.template, name, value)
                // prevState.template = prevState.template
            }
            return { ...prevState }
        })
    }

    const handleTemplateChange = (e: any, data: any) => {
        setState(prevState => {
            prevState.template = data?.value
            prevState.errors.template = ''
            return { ...prevState }
        })
    }
    const getMessageContent = () => {
        let text = ''
        forEach(state.template?.components, (item: ComponentModel) => {
            forEach(item.parameters, (parameter) => {
                if (parameter.text) {
                    item.text = item.text.replace(parameter.key, parameter.text);
                } else {
                    parameter.error = true
                    parameter.errorMessage = 'Required'
                }
            })
            text += item.text + '\n'
        })
        return text;
    }

    const formatTemplate = (text: string, parameters: Array<VaribleModel>) => {
        forEach(parameters, (parameter) => {
            if (parameter.text) {
                text = text.replace(parameter.key, parameter.text);
            }
        })
        return text;
    }
    const openDialog = () => {
        setState(prevState => {
            prevState.open = true
            return { ...prevState }
        });
    }
    const closeDialog = () => {
        setState(prevState => {
            prevState.open = false
            prevState.template = null
            return { ...prevState }
        });
    }

    const hasAccess = findIndex(selected?.users, (user) => user?.uuid === auth?.user?.uuid) > -1

    return (
        <Dialog
            open={state.open}
            onOpen={openDialog}
            className="add-contact"
            header={
                <Flex column>
                    <Text content={t('DIALOG.SETTING.TITLE')} />
                    <Text weight='light' size="small" content={t('DIALOG.SETTING.DESCRIPTION')} />
                </Flex>
            }
            footer={
                <Flex gap="gap.small" hAlign="end">
                    <Button disabled={state.loading} onClick={closeDialog}>{t('BUTTON.CANCEL')}</Button>
                    <Button disabled={state.loading} primary onClick={sendMessage}>{t('BUTTON.SEND')}</Button>
                </Flex>
            }
            content={
                <Form>
                    <Flex gap="gap.small" column>
                        {state.alert.open && <Flex.Item>
                            <Alert
                                success={state.alert.type === 'success'}
                                danger={state.alert.type === 'danger'}
                                info={state.alert.type === 'info'}
                                content={state.alert.message}
                            />
                        </Flex.Item>
                        }
                        <Flex.Item size="size.quarter">
                            <Dropdown
                                onChange={handleTemplateChange}
                                items={templates}
                                fluid
                                placeholder={t('INPUT.TEMPLATE.PLACEHOLDER')}
                                title={t('INPUT.TEMPLATE.TITLE')}
                                error={!!state?.errors?.template}

                            />
                        </Flex.Item>
                        {
                            map(state?.template?.components, (item: ComponentModel, key: number) => (
                                <Flex.Item key={key} size="size.full">
                                    <Card fluid>
                                        <Card.Header>
                                            <Text content={capitalize(item.type)} weight="bold" />
                                        </Card.Header>
                                        <Card.Body>{formatTemplate(item.text, item.parameters)} </Card.Body>
                                        {item.parameters?.length > 0 && <Flex gap="gap.small" column>
                                            <Card.Header>
                                                <Text content={t('LABEL.VARIABLES')} weight="bold" />
                                            </Card.Header>
                                            {map(item.parameters, (parameter, key2) => {
                                                return (
                                                    <Flex.Item key={key2} size="size.full">
                                                        <Input
                                                            required
                                                            fluid
                                                            error={!parameter.text}
                                                            value={parameter.text}
                                                            placeholder={parameter.key}
                                                            name={`components.${key}.parameters.${key2}.text`}
                                                            onChange={handleVariableChange}
                                                        />
                                                    </Flex.Item>
                                                )
                                            })}
                                        </Flex>
                                        }
                                    </Card>
                                </Flex.Item>
                            ))
                        }
                    </Flex>
                </Form>
            }
            trigger={<Button
                disabled={!hasAccess}
                fluid
                primary
                content={t('BUTTON.SEND_TEMPLATE')}
                icon={<SendIcon />} style={{ minWidth: '0px' }} />}
        />
    )
}