import { ExcelColorIcon, FilesEmptyIcon, FilesImageIcon, FilesPdfIcon, PowerPointColorIcon, WordColorIcon } from "@fluentui/react-northstar"
import { FC } from "react"

const FileIcon:FC<{type:string}> = (props): JSX.Element => {
    if  (props.type?.includes('image')) {
        return <FilesImageIcon />
    }
    if (props.type?.includes('pdf')) {
        return <FilesPdfIcon />
    }
    if (props.type?.includes('excel')) {
        return < ExcelColorIcon />
    }
    if (props.type?.includes('msword')) {
        return <WordColorIcon />
    }
    if (props.type?.includes('powerpoint')) {
        return <PowerPointColorIcon />
    }
    return <FilesEmptyIcon />
}

export default FileIcon;