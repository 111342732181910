import { ConversationModel, PageModel, ResponseModel, UserModel } from "Models";
import { Http } from "Request";


export default class UserService {
    public static users(params = {}): Promise<ResponseModel<PageModel<ConversationModel>>> {
        return new Promise((resolve, reject) => {
            Http.get('conversations', { params })
                .then((res) => {
                    return resolve(res?.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static update(uuid: string, params = {}): Promise<ResponseModel<UserModel>> {
        return new Promise((resolve, reject) => {
            Http.put(`users/${uuid}`, params)
                .then((res) => {
                    return resolve(res?.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static getManagers(params = {}): Promise<ResponseModel<Array<UserModel>>> {
        return new Promise((resolve, reject) => {
            Http.get('managers', { params })
                .then((res) => {
                    return resolve(res?.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static create(params = {}): Promise<ResponseModel<Array<ConversationModel>>> {
        return new Promise((resolve, reject) => {
            Http.post('conversations', params)
                .then((res) => {
                    return resolve(res?.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }
}