import {
    Alert,
    Button,
    Checkbox,
    Dialog,
    Dropdown,
    Flex,
    Form,
    FormTextArea,
    Input,
    SettingsIcon,
    Text
} from "@fluentui/react-northstar";
import { HOURS } from 'Helpers/Constants';
import { AUTO_REPLY_VALIDATION } from 'Helpers/Validations';

import { AutomationModel, Props } from "Models";
import AutomationService from 'Services/AutomationService';
import { Formik, FormikHelpers } from 'formik';
import { map } from 'lodash';
import { FC, useEffect, useState } from "react";
export const SettingDialog: FC<Props> = (props) => {
    const { t } = props;
    const [state, setState] = useState<{
        loading: boolean,
        alert: {
            open: boolean
            type: 'success' | 'danger' | 'info' | 'warning' | 'default'
            message: string
        },
        open: boolean
        automation: AutomationModel

    }>({
        loading: false,
        open: false,
        automation: new AutomationModel(),
        alert: {
            open: false,
            type: 'success',
            message: ''
        }
    })


    useEffect(() => {
        const getAutomation = () => {
            AutomationService.getAutomation().then((res) => {
                setState(prevState => {
                    prevState.automation = res.data;
                    return { ...prevState }
                })
            })
        }
        getAutomation()
    }, [])


    const openDialog = () => {
        setState(prevState => {
            prevState.open = true
            return { ...prevState }
        });
    }
    const closeDialog = () => {
        setState(prevState => {
            prevState.open = false
            return { ...prevState }
        });
    }

    const handleSubmit = (values: AutomationModel, helper: FormikHelpers<AutomationModel>) => {
        values.inOfficeOffset = values.inOfficeOffset ? parseFloat(values.inOfficeOffset as any) : 0
        values.outOfficeOffset = values.outOfficeOffset ? parseFloat(values.outOfficeOffset as any) : 0
        helper.setSubmitting(true);
        AutomationService.update(values).then((res) => {
            helper.setSubmitting(false);
            setState(prevState => {
                prevState.automation = res.data
                prevState.loading = false
                prevState.alert = {
                    open: true,
                    type: 'success',
                    message: 'Succesvol opgeslagen'
                }
                return { ...prevState }
            });
            setTimeout(() => {
                setState(prevState => {
                    prevState.open = false;
                    prevState.alert = {
                        open: false,
                        type: 'success',
                        message: ''
                    }
                    return { ...prevState }
                })
            }, 1000)
        })
    }
    const { automation } = state;
    return (
        <Formik enableReinitialize validationSchema={AUTO_REPLY_VALIDATION} initialValues={automation} onSubmit={handleSubmit}>
            {(formik) => (
                <Dialog
                    open={state.open}
                    onOpen={openDialog}
                    header={
                        <Flex column>
                            <Text content={t('DIALOG.SETTING.TITLE')} />
                            <Text weight='light' size="small" content={t('DIALOG.SETTING.DESCRIPTION')} />
                        </Flex>
                    }
                    footer={
                        <Flex gap="gap.small" hAlign="end">
                            <Button disabled={formik.isSubmitting} onClick={closeDialog}>{t('BUTTON.CANCEL')}</Button>
                            <Button disabled={formik.isSubmitting} type='submit' primary onClick={() => formik.handleSubmit()} loading={formik.isSubmitting}>{t('BUTTON.SAVE')}</Button>
                        </Flex>
                    }
                    content={
                        <Form>
                            <Flex gap="gap.small" column>
                                {state.alert.open && <Flex.Item>
                                    <Alert
                                        success={state.alert.type === 'success'}
                                        danger={state.alert.type === 'danger'}
                                        info={state.alert.type === 'info'}
                                        content={state.alert.message}
                                    />
                                </Flex.Item>
                                }

                                <Flex.Item size="size.full">
                                    <Flex gap="gap.small">
                                        <Flex.Item size='size.half'>
                                            <Checkbox
                                                checked={!!formik.values?.inOffice}
                                                label={t('INPUT.IN_OFFICE.TITLE')}
                                                onChange={(e, { checked }: any) => formik.setFieldValue('inOffice', checked)}
                                            />
                                        </Flex.Item>
                                        <Flex.Item size='size.half'>
                                            <Checkbox
                                                checked={!!formik.values?.outOffice}
                                                label={t('INPUT.OUT_OFFICE.TITLE')}
                                                onChange={(e, { checked }: any) => formik.setFieldValue('outOffice', checked)}
                                            />
                                        </Flex.Item>
                                    </Flex>
                                </Flex.Item>
                                <Flex.Item size="size.full" styles={{ paddingBottom: 10 }}>
                                    <Flex gap="gap.small">
                                        <Flex.Item size='size.half'>
                                            <Input
                                                fluid
                                                value={formik.values?.inOfficeOffset}
                                                label={t('INPUT.IN_OFFICE_OFFSET.TITLE')}
                                                name='inOfficeOffset'
                                                onChange={formik.handleChange}
                                                error={!!formik.errors?.inOfficeOffset}
                                            />
                                        </Flex.Item>
                                        <Flex.Item size='size.half'>
                                            <Input
                                                fluid
                                                value={formik.values?.outOfficeOffset}
                                                label={t('INPUT.OUT_OFFICE_OFFSET.TITLE')}
                                                name='outOfficeOffset'
                                                onChange={formik.handleChange}
                                                error={!!formik.errors?.outOfficeOffset}
                                            />
                                        </Flex.Item>
                                    </Flex>
                                </Flex.Item>
                                {map(formik.values?.days, (item, index) => (<Flex.Item key={index} size="size.full">
                                    <Flex gap="gap.small">
                                        <Flex.Item size='size.half' align='center'>
                                            <Text content={t(item.name)} align='start' size='medium' />
                                        </Flex.Item>
                                        <Flex.Item size='size.half'>
                                            <Dropdown
                                                fluid
                                                value={item.startTime}
                                                placeholder={t('INPUT.START_TIME.PLACEHOLDER')}
                                                items={HOURS}
                                                onChange={(e, data) => formik.setFieldValue(`days.${index}.startTime`, data.value)}
                                            />
                                        </Flex.Item>
                                        <Flex.Item size='size.half'>
                                            <Dropdown
                                                fluid
                                                value={item.endTime}
                                                placeholder={t('INPUT.END_TIME.PLACEHOLDER')}
                                                items={HOURS}
                                                onChange={(e, data) => formik.setFieldValue(`days.${index}.endTime`, data.value)}
                                            />
                                        </Flex.Item>
                                    </Flex>
                                </Flex.Item>))}

                                <Flex.Item size="size.full" styles={{ paddingTop: 10 }}>
                                    <FormTextArea value={formik.values?.inOfficeMessage} onChange={formik.handleChange} label={t('INPUT.IN_OFFICE_MESSAGE.TITLE')} name="inOfficeMessage" fluid />
                                </Flex.Item>
                                <Flex.Item size="size.full">
                                    <FormTextArea value={formik.values?.outOfficeMessage} onChange={formik.handleChange} label={t('INPUT.OUT_OFFICE_MESSAGE.TITLE')} name="outOfficeMessage" fluid />
                                </Flex.Item>
                            </Flex>
                        </Form>
                    }
                    trigger={<Button circular icon={<SettingsIcon outline />} primary title="Settings" />}
                />
            )}
        </Formik>
    )
}