
import {
    Alert,
    Button,
    Card,
    Dialog,
    Dropdown,
    Flex,
    Form,
    Input,
    Text
} from "@fluentui/react-northstar";
import countries from 'Libs/countries.json';
import { ComponentModel, Props, TemplateModel, VaribleModel } from "Models";
import { MessageService, UserService } from "Services";
import { capitalize, filter, find, forEach, map, set } from "lodash";
import { FC, useEffect, useState } from "react";
export const AddContact: FC<Props & { open: boolean, onClose: () => void }> = (props) => {
    const { open, onClose, t } = props
    const [templates, setTemplates] = useState<Array<TemplateModel>>([])
    const [state, setState] = useState<{
        loading: boolean,
        alert: {
            open: boolean
            type: 'success' | 'danger' | 'info' | 'warning' | 'default'
            message: string
        },
        template: TemplateModel | null
        fields: {
            phone: string
            firstName: string
            lastName: string
            code: string
        }
        errors: {
            code: string,
            phone: string
            firstName: string
            lastName: string;
            template: string
        }
    }>({
        loading: false,
        template: null,
        alert: {
            open: false,
            type: 'success',
            message: ''
        },
        fields: {
            phone: '',
            firstName: '',
            lastName: '',
            code: ""
        },
        errors: {
            code: '',
            phone: '',
            firstName: '',
            lastName: '',
            template: ''
        }
    })
    const addUser = () => {
        let error = false
        if (!state.fields.firstName) {
            setState((prevState) => {
                prevState.errors.firstName = 'Required'
                return { ...prevState }
            })
            error = true
        }
        if (!state.fields.lastName) {
            setState((prevState) => {
                prevState.errors.lastName = 'Required'
                return { ...prevState }
            })
            error = true
        }
        if (!state.fields.phone) {
            setState((prevState) => {
                prevState.errors.phone = 'Required'
                return { ...prevState }
            })
            error = true
        }
        const check = find(countries, con => parseInt(con.code) === parseInt(state.fields.code))
        if (!check) {
            setState((prevState) => {
                prevState.errors.code = 'Required'
                return { ...prevState }
            })
            error = true
        }

        if (!state.template) {
            setState((prevState) => {
                prevState.errors.template = 'Required'
                return { ...prevState }
            })
            error = true
        }
        if (error) {
            return
        }
        setState((prevState) => {
            prevState.loading = true
            return { ...prevState }
        })
        const payload = {
            ...state.fields,
            phone: `${state.fields.code}${state.fields.phone}`,
            content: getMessageContent(),
            template: {
                name: state.template?.name,
                language: state.template?.language,
                components: state.template?.components
            }
        }

        UserService.create(payload).then((resp) => {
            if (resp.status === 200) {
                setState((prevState) => {
                    prevState.alert = {
                        open: true,
                        type: 'success',
                        message: 'Succesvol verstuurd'
                    }
                    prevState.template = null
                    prevState.fields = {
                        phone: '',
                        firstName: '',
                        lastName: '',
                        code: "31"
                    }
                    prevState.errors = {
                        code: '',
                        phone: '',
                        firstName: '',
                        lastName: '',
                        template: ''
                    }
                    return { ...prevState }
                });
                setTimeout(() => {
                    setState((prevState) => {
                        prevState.loading = false
                        prevState.alert = {
                            open: false,
                            type: 'success',
                            message: ''
                        }
                        return { ...prevState }
                    })
                    props.onClose()
                }, 1000)
            } else {
                if (resp.status === 400) {
                    setState((prevState) => {
                        prevState.loading = false;
                        prevState.alert = {
                            open: true,
                            type: 'danger',
                            message: resp.message
                        }
                        return { ...prevState }
                    });
                }

                if (resp.status === 500) {
                    setState((prevState) => {
                        prevState.loading = false;
                        prevState.alert = {
                            open: true,
                            type: 'danger',
                            message: 'Het bericht kan niet verstuurd worden, controleer het telefoonnummer.'
                        }
                        return { ...prevState }
                    });
                    setTimeout(() => {
                        setState((prevState) => {
                            prevState.alert = {
                                open: false,
                                type: 'success',
                                message: ''
                            }
                            return { ...prevState }
                        })
                    }, 1000)
                }
            }
        });
    }
    const getVariables = (text: string): Array<VaribleModel> => {
        const arr: Array<VaribleModel> = [];
        for (let i = 0; i < 100; i++) {
            if (text.includes(`{{${i}}}`)) {
                arr.push({
                    key: `{{${i}}}`,
                    text: '',
                    type: 'text'
                })
            }
        }
        return arr;
    }


    useEffect(() => {
        const getTemplates = () => {
            MessageService.getTemplates().then((resp) => {
                if (resp) {
                    forEach(resp.data, (template) => {
                        template.components = map(filter(template.components, (component) => ['BODY', 'BUTTON', 'CAROUSEL', 'HEADER', 'LIMITED_TIME_OFFER', 'ORDER_STATUS'].includes(component.type)), (component) => {
                            component.parameters = getVariables(component.text)
                            return component
                        })
                    })
                    const tems = map(resp.data, item => ({
                        ...item,
                        key: item.id,
                        header: item?.name,
                    }));
                    setTemplates(tems)
                }
            });
        }
        getTemplates();
        return () => {
            setTemplates([])
        }
    }, [])

    const handleChange = (e: React.SyntheticEvent<HTMLElement>, { name, value }: any) => {
        setState(prevState => {
            prevState.fields[name] = value
            prevState.errors[name] = ''
            return { ...prevState }
        });
    }

    const handleVariableChange = (e: React.SyntheticEvent<HTMLElement>, { name, value }: any) => {
        setState(prevState => {
            if (prevState.template) {
                set(prevState.template, name, value)
                //  prevState.template = prevState.template
            }
            return { ...prevState }
        })
    }
    const handleCodeChange = (e: any, data: any) => {
        setState(prevState => {
            prevState.fields['code'] = data?.value?.code
            prevState.errors['code'] = ''
            return { ...prevState }
        });
    }

    const handleTemplateChange = (e: any, data: any) => {
        setState(prevState => {
            prevState.template = data?.value
            prevState.errors.template = ''
            return { ...prevState }
        })
    }

    const getMessageContent = () => {
        let text = ''
        forEach(state.template?.components, (item: ComponentModel) => {
            forEach(item.parameters, (parameter) => {
                if (parameter.text) {
                    item.text = item.text.replace(parameter.key, parameter.text);
                }
            })
            text += item.text + '\n'
        })
        return text;
    }

    const formatTemplate = (text: string, parameters: Array<VaribleModel>) => {
        forEach(parameters, (parameter) => {
            if (parameter.text) {
                text = text.replace(parameter.key, parameter.text);
            }
        })
        return text;
    }

    const options = map(countries, (item: { code: string, name: string }, key: number) => ({
        key: key,
        code: parseInt(item?.code),
        selected: item?.code === state?.fields?.code,
        header: `+${item?.code}`,
    }))

    return (
        <Dialog
            open={props.open}
            className="add-contact"
            header={
                <Flex column>
                    <Text content={t('DIALOG.ADD_CONVERSATION.TITLE')} />
                    <Text weight='light' size="small" content={t('DIALOG.ADD_CONVERSATION.DESCRIPTION')} />
                </Flex>
            }
            footer={
                <Flex gap="gap.small" hAlign="end">
                    <Button disabled={state.loading} onClick={props.onClose}>{t('BUTTON.CANCEL')}</Button>
                    <Button disabled={state.loading} primary onClick={addUser}>{t('BUTTON.ADD')}</Button>
                </Flex>
            }
            content={
                <Form>
                    <Flex gap="gap.small" column>
                        {state.alert.open && <Flex.Item>
                            <Alert
                                success={state.alert.type === 'success'}
                                danger={state.alert.type === 'danger'}
                                info={state.alert.type === 'info'}
                                content={state.alert.message}
                            />
                        </Flex.Item>
                        }
                        <Flex.Item size="size.full">
                            <Flex gap="gap.small">
                                <Flex.Item size="size.half">
                                    <Input
                                        required
                                        fluid
                                        value={state?.fields?.firstName}
                                        placeholder={t('INPUT.FIRST_NAME.PLACEHOLDER')}
                                        name="firstName"
                                        onChange={handleChange}
                                        error={!!state?.errors?.firstName}
                                    />
                                </Flex.Item>
                                <Flex.Item size="size.half">
                                    <Input
                                        required
                                        fluid
                                        value={state?.fields?.lastName}
                                        placeholder={t('INPUT.LAST_NAME.PLACEHOLDER')}
                                        name="lastName"
                                        onChange={handleChange}
                                        error={!!state?.errors?.lastName}
                                    />
                                </Flex.Item>
                            </Flex>
                        </Flex.Item>
                        <Flex.Item size="size.full">
                            <Flex gap="gap.small">
                                <Flex.Item size="size.quarter">
                                    <Dropdown
                                        error={!!state?.errors?.code}
                                        search
                                        onChange={handleCodeChange}
                                        items={options}
                                        value={find(options, (item: any) => item?.code === state?.fields?.code)}
                                        activeSelectedIndex={0}
                                        fluid
                                        placeholder={t('INPUT.CODE.PLACEHOLDER')}
                                        title={t('INPUT.CODE.TITLE')}
                                    />
                                </Flex.Item>
                                <Flex.Item size="size.full">
                                    <Input
                                        fluid
                                        type="number"
                                        value={state?.fields?.phone}
                                        placeholder={t('INPUT.PHONE.PLACEHOLDER')}
                                        name="phone"
                                        onChange={handleChange}
                                        error={!!state?.errors?.phone}
                                    />
                                </Flex.Item>
                            </Flex>
                        </Flex.Item>
                        <Flex.Item size="size.quarter">
                            <Dropdown
                                onChange={handleTemplateChange}
                                items={templates}
                                fluid
                                placeholder={t('INPUT.TEMPLATE.PLACEHOLDER')}
                                title={t('INPUT.TEMPLATE.TITLE')}
                                error={!!state?.errors?.template}
                            />
                        </Flex.Item>
                        {
                            map(state?.template?.components, (item: ComponentModel, key: number) => (
                                <Flex.Item key={key} size="size.full">
                                    <Card fluid>
                                        <Card.Header>
                                            <Text content={capitalize(item.type)} weight="bold" />
                                        </Card.Header>
                                        <Card.Body>{formatTemplate(item.text, item.parameters)} </Card.Body>
                                        {item.parameters?.length > 0 && <Flex gap="gap.small" column>
                                            <Card.Header>
                                                <Text content="Variables" weight="bold" />
                                            </Card.Header>
                                            {map(item.parameters, (parameter, key2) => {
                                                return (
                                                    <Flex.Item key={key2} size="size.full">
                                                        <Input
                                                            required
                                                            fluid
                                                            value={parameter.text}
                                                            placeholder={parameter.key}
                                                            name={`components.${key}.parameters.${key2}.text`}
                                                            onChange={handleVariableChange}
                                                            error={!parameter.text}
                                                        />
                                                    </Flex.Item>
                                                )
                                            })}
                                        </Flex>
                                        }
                                    </Card>
                                </Flex.Item>
                            ))
                        }
                    </Flex>
                </Form>
            }
        />
    )
}