import { useTranslation } from "react-i18next";

import i18next from 'i18next';
import { initReactI18next } from "react-i18next";

import en from './Locales/en.json';
import nl from './Locales/nl.json';

i18next.use(initReactI18next).init({
    lng: 'nl',
    debug: true,
    resources: {
        nl: {
            translation: nl
        },
        en: {
            translation: en
        }
    }
});


export {
    i18next,
    useTranslation
};
