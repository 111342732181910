
import { Button, Dialog, Flex, Text } from "@fluentui/react-northstar";
import { Props } from "Models";
import { FC } from "react";

export const ConformationDialog: FC<Props & { onConfirm: () => void, open: boolean, onCancel: () => void, title: string, subTitle?: string }> = (props) => {
    const { t, title, subTitle, onCancel, onConfirm, open } = props;
    return (
        <Dialog open={open}
            header={
                <Flex column>
                    <Text content={title} />
                    <Text weight='light' size="small" content={subTitle || ''} />
                </Flex>
            }
            content={
                <Flex gap="gap.small" column></Flex>
            }
            footer={
                <Flex gap="gap.small" hAlign="end">
                    <Button onClick={onCancel}>{t('BUTTON.CANCEL')}</Button>
                    <Button primary onClick={onConfirm}>{t('BUTTON.ADD')}</Button>
                </Flex>
            }
        />
    )
}