import { Attachment, Chat, DownloadIcon, Flex, Image, Layout, Skeleton, Text } from '@fluentui/react-northstar';
import DefaultAttachment from 'Assets/DefaultAttachment.svg';
import { MessageModel, Props } from "Models";
import { MessageService } from "Services";
import moment from 'moment';
import 'moment/locale/nl';
import { FC, useEffect, useState } from "react";
import FileIcon from "./FileIcon";
moment.locale('nl');
export const MessageContent: FC<Props & { message: MessageModel, onLoad?: () => void, child?: boolean }> = (props) => {
    const { message, onLoad, t, child } = props;
    const [file, setFile] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getFile = () => {
            if (!['text'].includes(message.type) && message?.attachment) {
                MessageService.getMedia(message.uuid, { responseType: 'blob' }).then(resp => {
                    setFile(URL.createObjectURL(resp))
                    setLoading(false)
                })
            }
        }
        getFile()
        return () => {
            setFile('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message.uuid])

    const handleDownload = () => {
        const a = document.createElement('a');
        a.href = file;
        a.download = message.attachment.fileName ? message.attachment.fileName : "";
        a.style.display = 'none';
        document.body.append(a);
        a.click();
    }

    if (message.type === 'text') {
        const time = message.parent ? moment(message.parent?.timestamp * 1000).format('HH:mm') : null;
        return <Flex column>
            {message.parent && <Chat.Item className='parent-message' message={
                <Chat.Message
                    content={<MessageContent t={t} message={message.parent} child={true} />}
                    author={`${message.parent?.user?.firstName || ''} ${message.parent?.user?.lastName || ''}`}
                    timestamp={time}
                    mine={false}
                />
            } />}
            <Text style={{ whiteSpace: 'pre-wrap' }} content={message.content} />
        </Flex>
    }
    if (message?.attachment && message?.user?.role === 'CLIENT' && message?.timestamp && moment(message?.timestamp * 1000).isBefore(moment().subtract(30, 'days'))) {
        return (<Flex column>
            <Text content={message?.attachment?.caption ? message?.attachment?.caption : ''} />
            <Image
                width={200}
                style={{ margin: 'auto' }}
                src={DefaultAttachment}
            />
            <Text content={t('LABEL.MESSAGE_RETAINTION')} style={{ paddingTop: 10 }} />
        </Flex>)
    }
    if (message?.attachment?.mimeType?.includes('image')) {
        const toDay = moment()
        const days = toDay.diff(moment(message?.timestamp * 1000), 'days')
        const day = 30 - (days < 30 ? days : 30);
        return <Layout style={{ maxWidth: child ? '250px' : '400px' }}>
            {loading ? (<Skeleton animation="pulse">
                <Skeleton.Shape width={child ? '250px' : '400px'} height={child ? '80px' : "400px"} />
            </Skeleton>) : (
                <>
                    <Attachment
                        header={message?.attachment?.fileName}
                        description={
                            <Flex column>

                                <Image
                                    fluid
                                    src={file}
                                    onLoad={onLoad}
                                    style={{ width: child ? '80px' : '100%' }}
                                />
                                {!!(message?.user?.role === 'CLIENT' && day) && <Text style={{ paddingTop: 10 }} content={t('LABEL.MESSAGE_RETAINTION_DAY', { day })} />}
                            </Flex>
                        }
                        action={{
                            icon: <DownloadIcon />,
                            onClick: handleDownload,
                            title: 'Download',
                        }}
                    />
                    <Text content={message?.attachment?.caption ? message?.attachment?.caption : ''} />
                </>
            )}
        </Layout>
    }

    if (message?.attachment?.mimeType?.includes('audio')) {
        return <Layout>
            {message?.attachment?.caption && <p> {message?.attachment?.caption || ''}</p>}
            {loading ? (<Skeleton animation="pulse">
                <Skeleton.Shape width="300px" height="54px" />
            </Skeleton>) : (
                <audio src={file} controls onLoad={onLoad} />
            )}
        </Layout>
    }
    if (message?.attachment?.mimeType?.includes('video')) {
        return <Layout style={{ width: '400px' }}>
            {message?.attachment?.caption && <p> {message?.attachment?.caption || ''}</p>}
            {loading ? (<Skeleton animation="pulse">
                <Skeleton.Shape width="400px" height="300px" />
            </Skeleton>) : (
                <video src={file} controls width={400} onLoad={onLoad} />
            )}
        </Layout>
    }

    return (<Attachment
        icon={<FileIcon type={message?.attachment?.mimeType} />}
        header={message?.attachment?.fileName}
        description={message?.attachment?.caption || ''}
        action={{
            icon: <DownloadIcon />,
            onClick: handleDownload,
            title: 'Download',
        }}
    />)
}