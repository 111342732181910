import { ConversationModel, ResponseModel } from "Models";
import { Http } from "Request";


export default class ConversationService {
    public static update(uuid: string, params = {}): Promise<ResponseModel<ConversationModel>> {
        return new Promise((resolve, reject) => {
            Http.put(`conversations/${uuid}`, params)
                .then((res) => {
                    return resolve(res?.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static delete(uuid: string, params = {}): Promise<ResponseModel<ConversationModel>> {
        return new Promise((resolve, reject) => {
            Http.delete(`conversations/${uuid}`, { params })
                .then((res) => {
                    return resolve(res?.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static getPinnedConversations(params = {}): Promise<ResponseModel<Array<{ conversationId: string }>>> {
        return new Promise((resolve, reject) => {
            Http.get(`pinned-conversations`, params)
                .then((res) => {
                    return resolve(res?.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static pinnedConversation(params = {}): Promise<ResponseModel<Array<{ conversationId: string }>>> {
        return new Promise((resolve, reject) => {
            Http.post(`pinned-conversations`, params)
                .then((res) => {
                    return resolve(res?.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }
    public static unPinnedConversation(id: string, params = {}): Promise<ResponseModel<Array<{ conversationId: string }>>> {
        return new Promise((resolve, reject) => {
            Http.delete(`pinned-conversations/${id}`, { params })
                .then((res) => {
                    return resolve(res?.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static download(id: string, params = {}): Promise<Blob> {
        return new Promise((resolve, reject) => {
            Http.get(`conversations/${id}/download`, { params, responseType: 'blob' })
                .then((res) => {
                    return resolve(res?.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static markAsUnread(id: string, params = {}): Promise<ResponseModel<ConversationModel>> {
        return new Promise((resolve, reject) => {
            Http.put(`conversations/${id}/fields`, params)
                .then((res) => {
                    return resolve(res?.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }
}