import { number, object, string } from "yup";

export const AUTO_REPLY_VALIDATION = object().shape({
    inOfficeOffset: number().min(0).max(24).required(),
    outOfficeOffset: number().min(0).max(24).required(),
})

export const CONTACT = (edit = false) => {
    return object().shape({
        firstName: string().required(),
        lastName: string().required()
    })
}

export const FEEDBACK = (edit = false) => {
    return object().shape({
        email: string().email().required(),
        body: string().required()
    })
}