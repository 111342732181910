import { MessageModel, PageModel, ResponseModel, TemplateModel } from "Models";
import { Http } from "Request";


export default class MessageService {
    public static messages(params = {}): Promise<ResponseModel<PageModel<MessageModel>>> {
        return new Promise((resolve, reject) => {
            Http.get('messages', { params })
                .then((res) => {
                    return resolve(res?.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }
    public static sendMessage(params = {}): Promise<ResponseModel<MessageModel>> {
        return new Promise((resolve, reject) => {
            Http.post('messages', params)
                .then((res) => {
                    return resolve(res?.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static getMedia(id: string, params = {}): Promise<Blob> {
        return new Promise((resolve, reject) => {
            Http.get(`messages/${id}/media`, params)
                .then((res) => {
                    resolve(res?.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    public static getTemplates(params = {}): Promise<ResponseModel<Array<TemplateModel>>> {
        return new Promise((resolve, reject) => {
            Http.get('templates', { params })
                .then((res) => {
                    return resolve(res?.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }
}