export class Props {
    public t!: (param: string, options?: { [key: string]: any }) => string
}
export class ConversationModel {
    public uuid!: string
    public type!: 'INDIVIDUAL' | 'GRPUP';
    public clientId!: string;
    public timestamp?: number
    public createdAt?: string
    public updatedAt?: string
    public posts?: Array<MessageModel>
    public users?: Array<UserModel>
    public client!: UserModel
    public count!: number
    public status!: 'ACTIVE' | 'INACTIVE' | 'DELETED' | 'ARCHIVED'
}

export class UserModel {
    public uuid!: string
    public tenantId!: string
    public role!: 'CLIENT' | 'ADMIN' | 'SUPER_ADMIN'
    public firstName!: string
    public lastName!: string
    public color!: string
    public email!: string
    public phone!: string
    public timestamp!: number
    public createdAt!: string
    public updatedAt!: string
}

export class MessageModel {
    public uuid!: string;
    public id!: string;
    public parentId!: string | null;
    public conversationId!: string;
    public senderId!: string;
    public type!: string;
    public content!: string;
    public timestamp!: number;
    public attachment!: AttachmentModel;
    public status!: 'READ' | 'DELIVERED'
    public posts!: Array<MessageModel>
    public parent?: MessageModel
    public user!: UserModel
}


export class PageModel<T> {
    public items!: Array<T>;
    public total!: number;
    public currentPage!: number;
    public lastPage!: number;
}

export class AttachmentModel {
    public uuid!: string;
    public id!: string;
    public type!: string
    public mimeType!: string;
    public fileName!: string;
    public caption!: string;
}


export class ResponseModel<T> {
    data!: T
    status!: number
    message!: string
}

export class TemplateModel {
    public name!: string
    public previous_category!: string
    public components!: Array<ComponentModel>
    public language!: string
    public status!: string
    public category!: string
    public id!: string
}

export class ComponentModel {
    public type!: string
    public text!: string
    public example!: Example
    public parameters!: Array<VaribleModel>
}

export class VaribleModel {
    public text!: string
    public key!: string
    public type!: string
    public error?: boolean
    public errorMessage?: string
}

export interface Example {
    body_text: string[][]
}

export class AutomationModel {
    public uuid!: string
    public name!: string
    public type!: string
    public inOffice!: boolean
    public outOffice!: boolean
    public inOfficeMessage!: string
    public outOfficeMessage!: string
    public inOfficeOffset!: number
    public outOfficeOffset!: number
    public days!: Array<AutomationDay>
    public status!: string
    public createdAt!: string
    public updatedAt!: string
}

export class AutomationDay {
    public uuid!: string
    public automationId!: string
    public day!: string
    public name!: string
    public startTime!: string
    public endTime!: string
}
