
import {
    Alert,
    Button,
    Dialog,
    Flex,
    Form,
    Input,
    QuestionCircleIcon,
    Text,
    TextArea
} from "@fluentui/react-northstar";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { FEEDBACK } from "Helpers/Validations";
import { Props } from "Models";
import { FC, useContext, useState } from "react";
import { FeebackService } from "Services";
import { TeamsFxContext } from "./Context";

export const FeedbackDialog: FC<Props> = (props) => {
    const { t } = props;
    const { auth } = useContext(TeamsFxContext);
    const [state, setState] = useState<{
        open: boolean,
        loading: boolean,
        alert: {
            open: boolean
            type: 'success' | 'danger' | 'info' | 'warning' | 'default'
            message: string
        }
    }>({
        open: false,
        loading: false,
        alert: {
            open: false,
            type: 'success',
            message: ''
        }
    })

    const formik = useFormik({
        initialValues: {
            email: auth?.user?.email,
            body: '',
            files: []
        },
        validationSchema: FEEDBACK(),
        onSubmit: (values) => {
            handleFeedbackSubmit(values)
        }
    })

    const mutation = useMutation({
        mutationFn: (params: FormData) => FeebackService.sendFeedback(params),
        onSuccess: (resp) => {
            setState(prevState => {
                prevState.alert = {
                    open: true,
                    type: 'success',
                    message: resp.message
                }
                return { ...prevState }
            });

            setTimeout(() => {
                setState(prevState => {
                    prevState.alert = {
                        open: false,
                        type: 'success',
                        message: ''
                    }
                    return { ...prevState }
                })
                closeDialog()
            }, 1000)
        }
    })

    const openDialog = () => {
        setState(prevState => {
            prevState.open = true
            return { ...prevState }
        });
    }
    const closeDialog = () => {
        formik.resetForm();
        setState(prevState => {
            prevState.open = false
            return { ...prevState }
        });
    }

    const handleFeedbackSubmit = (values: { email?: string, body: string, files: File[] }) => {
        const param = new FormData();
        param.append('email', values.email!);
        param.append('body', values.body);
        for (const file of values.files) {
            param.append('files', file);
        }
        mutation.mutate(param)
    }

    return (
        <Dialog
            open={state.open}
            onOpen={openDialog}
            className="add-contact"
            header={
                <Flex column>
                    <Text content={t('DIALOG.FEEDBACK.TITLE')} />
                    <Text weight='light' size="small" content={t('DIALOG.FEEDBACK.DESCRIPTION')} />
                </Flex>
            }
            footer={
                <Flex gap="gap.small" hAlign="end">
                    <Button disabled={mutation.isPending} onClick={closeDialog}>{t('BUTTON.CANCEL')}</Button>
                    <Button disabled={mutation.isPending} primary onClick={formik.submitForm}>{t('BUTTON.ADD')} </Button>
                </Flex>
            }
            content={
                <Form>
                    <Flex gap="gap.medium" column>
                        {state.alert.open && <Flex.Item>
                            <Alert
                                success={state.alert.type === 'success'}
                                danger={state.alert.type === 'danger'}
                                info={state.alert.type === 'info'}
                                content={state.alert.message}
                            />
                        </Flex.Item>
                        }
                        <Flex.Item size="size.quarter">
                            <Input
                                required
                                fluid
                                value={formik.values.email}
                                placeholder={t('INPUT.EMAIL.PLACEHOLDER')}
                                label={t('INPUT.EMAIL.TITLE')}
                                name="email"
                                onChange={formik.handleChange}
                                error={!!formik?.errors?.email}
                            />
                        </Flex.Item>
                        <Flex.Item size="size.quarter">
                            <Flex gap="gap.small" column>
                                <Text content={t('INPUT.BODY.TITLE')} />
                                <TextArea
                                    placeholder={t('INPUT.BODY.PLACEHOLDER')}
                                    required
                                    fluid
                                    value={formik.values.body}
                                    name="body"
                                    onChange={formik.handleChange}
                                    error={!!formik?.errors?.body}
                                    style={{ minHeight: '100px' }}

                                />
                            </Flex>
                        </Flex.Item>
                        <Flex.Item size="size.quarter">
                            <Input
                                fluid
                                placeholder={t('INPUT.FILE.TITLE')}
                                label={t('INPUT.FILE.TITLE')}
                                name="files"
                                type="file"
                                onChange={(e: any) => {
                                    formik.setFieldValue('files', e?.target?.files || [])
                                }}
                                multiple
                            />
                        </Flex.Item>
                    </Flex>
                </Form>
            }
            trigger={<Button
                circular
                primary
                icon={<QuestionCircleIcon outline />} />}
        />
    )
}