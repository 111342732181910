import { Theme } from "@fluentui/react-components";
import { TeamsUserCredential } from "@microsoft/teamsfx";
import { UserModel } from "Models";
import { createContext } from "react";

export const TeamsFxContext = createContext<{
  theme?: Theme;
  themeString: string;
  teamsUserCredential?: TeamsUserCredential;
  auth: { login: boolean, user: UserModel | null, token: string },
  setAuth: (params: { login: boolean, user: UserModel | null, token: string }) => void;
}>({
  theme: undefined,
  themeString: "",
  teamsUserCredential: undefined,
  auth: {
    login: false,
    user: null,
    token: ""
  },
  setAuth: (params: { login: boolean, user: UserModel | null, token: string }) => {
    JSON.stringify(params)
    localStorage.setItem("auth", JSON.stringify(params))
  }
});
